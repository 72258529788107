.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: calc(14px + 2vmin);
  pointer-events: none;
  margin-right: 15px;
}

.Site-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Site-helper {
	margin: 5px;
	margin-bottom: 15px;
  font-size: calc(10px + 1vmin);
}

.App-body {
  font-size: calc(10px + 1vmin);
}

.App-link{
  color : rgb(252, 41, 41);
}

.App-warning{
  color : rgb(252, 41, 41);
  font-size : 14px;
}
